// Dependencies
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Contact from '../components/Contact'
import Helmet from '../components/Helmet'
import Social from '../components/Social'

// Helpers
import { getNavigationProps, getExtraSections } from '../utils'

const ContactPage = (props) => {
  const langLinks = [
    {
      url: props.path.replace(`/${props.pageContext.lang}`, ''),
      lang: props.pageContext.lang,
      active: true,
    },
    ...get(props, 'data.prismicContactContent.alternate_languages'),
  ]

  const sections = [
    get(props, 'data.allPrismicServicesContent'),
    get(props, 'data.allPrismicTestimonialsContent'),
    get(props, 'data.allPrismicGalleriesContent'),
  ]

  const navigationProps = getNavigationProps(props)

  const followCopy = get(
    props,
    'data.allPrismicAboutContent.nodes[0].data.follow_copy.html'
  )

  const heroCopy = get(props, 'data.prismicContactContent.data.hero_copy')
  const heroImage = get(
    props,
    'data.prismicContactContent.data.hero_image.document.data'
  )
  const heroProps = {
    title: heroCopy,
    image: heroImage,
  }

  const introTitle = get(props, 'data.prismicContactContent.data.intro_title')
  const introCopy = get(
    props,
    'data.prismicContactContent.data.intro_copy.html'
  ).replace(/(https?:\/\/.\/)/gim, '/')

  const firstName = get(props, 'data.prismicContactContent.data.first_name')
  const firstNameValidation = get(
    props,
    'data.prismicContactContent.data.first_name_validation'
  )
  const lastName = get(props, 'data.prismicContactContent.data.last_name')
  const lastNameValidation = get(
    props,
    'data.prismicContactContent.data.last_name_validation'
  )
  const telephone = get(props, 'data.prismicContactContent.data.telephone')
  const telephoneValidation = get(
    props,
    'data.prismicContactContent.data.telephone_validation'
  )
  const email = get(props, 'data.prismicContactContent.data.email')
  const emailValidation = get(
    props,
    'data.prismicContactContent.data.email_validation'
  )
  const subject = get(props, 'data.prismicContactContent.data.subject')
  const subjectValidation = get(
    props,
    'data.prismicContactContent.data.subject_validation'
  )
  const message = get(props, 'data.prismicContactContent.data.message')
  const messageValidation = get(
    props,
    'data.prismicContactContent.data.message_validation'
  )
  const sendMessage = get(props, 'data.prismicContactContent.data.send_message')
  const successMessage = get(
    props,
    'data.prismicContactContent.data.success_message'
  )
  const failMessage = get(props, 'data.prismicContactContent.data.fail_message')
  const invalidMessage = get(
    props,
    'data.prismicContactContent.data.invalid_message'
  )

  const contactProps = {
    introTitle,
    introCopy,
    firstName,
    firstNameValidation,
    lastName,
    lastNameValidation,
    telephone,
    telephoneValidation,
    email,
    emailValidation,
    subject,
    subjectValidation,
    message,
    messageValidation,
    sendMessage,
    successMessage,
    failMessage,
    invalidMessage,
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
  }

  const generalContent = get(
    props,
    'data.allPrismicGeneralContent.nodes[0].data'
  )

  const socialProps = {
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
    followCopy,
    latestPostsLabel: generalContent.latest_posts_label,
    loadMorePosts: generalContent.load_more_posts,
  }

  return (
    <Layout {...{ ...navigationProps, langLinks }}>
      <Helmet {...generalContent} />
      <Hero {...heroProps} compact />
      <Contact {...contactProps} />
      {getExtraSections(sections, generalContent, props.pageContext.lang)}
      <Social {...socialProps} />
    </Layout>
  )
}

export const contactPageQuery = graphql`
  query($id: String, $lang: String) {
    allPrismicNavigation(filter: { lang: { eq: $lang } }) {
      nodes {
        lang
        data {
          links {
            page {
              uid
              lang
            }
            label
          }
        }
      }
    }
    allPrismicGeneralContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          ...generalContent
        }
      }
    }
    allPrismicAboutContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          follow_copy {
            html
          }
        }
      }
    }
    prismicContactContent(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        url
        lang
      }
      data {
        hero_copy
        hero_image {
          document {
            ... on PrismicHeroImage {
              data {
                portrait {
                  fluid(maxWidth: 1650, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
                landscape {
                  fluid(maxWidth: 2800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
        intro_title
        intro_copy {
          html
        }
        first_name
        first_name_validation
        last_name
        last_name_validation
        telephone
        telephone_validation
        email
        email_validation
        subject
        subject_validation
        message
        message_validation
        send_message
        success_message
        fail_message
        invalid_message
      }
    }
    allPrismicGalleriesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...galleriesContent
        }
      }
    }
    allPrismicServicesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...servicesContent
        }
      }
    }
    allPrismicTestimonialsContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...testimonialsContent
        }
      }
    }
  }
`

export default ContactPage
