// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import { loadingSVG, instagramSVG, facebookSVG } from '../../assets/svgs'

// Styles
import './contact.scss'

const Contact = ({
  introTitle,
  introCopy,
  instagram,
  facebook,
  firstName,
  firstNameValidation,
  lastName,
  lastNameValidation,
  telephone,
  telephoneValidation,
  email,
  emailValidation,
  subject,
  subjectValidation,
  message,
  messageValidation,
  sendMessage,
  successMessage,
  failMessage,
  invalidMessage,
}) => {
  const validateRequired = (value) => {
    return value.trim().length > 0
  }

  const validateEmail = (value) => {
    return (
      value.trim().length > 0 &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    )
  }

  const validatePhone = (value) => {
    return value.trim().length > 0 && /^[+() \-0-9]{5,}$/.test(value)
  }

  const formInitialState = {
    firstName: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    lastName: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    email: {
      value: '',
      error: false,
      validation: validateEmail,
    },
    telephone: {
      value: '',
      error: false,
      validation: validatePhone,
    },
    subject: {
      value: '',
      error: false,
      validation: validateRequired,
    },
    message: {
      value: '',
      error: false,
      validation: validateRequired,
    },
  }

  const [form, setForm] = useState(formInitialState)
  const [formStatus, setFormStatus] = useState('')

  const updateForm = (data) => {
    const newForm = { ...form }
    if (newForm[data.field].error) {
      const isValid = newForm[data.field].validation(data.value)
      newForm[data.field].error = !isValid
    }
    newForm[data.field].value = data.value

    setForm(newForm)
  }
  const validateField = (data) => {
    const newForm = { ...form }
    const isValid = newForm[data.field].validation(newForm[data.field].value)
    newForm[data.field].error = !isValid
    setForm(newForm)
  }

  const validateForm = () => {
    const newForm = { ...form }
    let isFormValid = true
    Object.keys(form).forEach((key) => {
      const isValid = newForm[key].validation(newForm[key].value)
      newForm[key].error = !isValid
      if (!isValid) {
        isFormValid = false
      }
    })

    setForm(newForm)
    return isFormValid
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (formStatus === 'sending') return
    const isFormValid = validateForm()

    if (isFormValid) {
      setFormStatus('sending')
      const sentForm = { ...form }

      try {
        await axios({
          method: 'post',
          url: '/.netlify/functions/contact-form',
          data: {
            firstName: sentForm.firstName.value,
            lastName: sentForm.lastName.value,
            email: sentForm.email.value,
            telephone: sentForm.telephone.value,
            subject: sentForm.subject.value,
            message: sentForm.message.value,
          },
        })

        setFormStatus('success')
        trackCustomEvent({
          category: 'Contact Form',
          action: 'Submit',
          label: 'Success',
        })
        setForm(formInitialState)
      } catch (e) {
        setFormStatus('fail')
        trackCustomEvent({
          category: 'Contact Form',
          action: 'Submit',
          label: 'Fail',
        })
      }
    } else {
      setFormStatus('invalid')
      trackCustomEvent({
        category: 'Contact Form',
        action: 'Submit',
        label:
          'Invalid [' +
          Object.keys(form)
            .filter((key) => form[key].error === true)
            .join(', ') +
          ']',
      })
      setTimeout(() => {
        setFormStatus('')
      }, 3000)
    }
  }

  return (
    <>
      <section>
        <div className="section-content padded">
          <div className="contact">
            {(introTitle || introCopy) && (
              <>
                <h2>{introTitle}</h2>
                <div
                  className="compact"
                  dangerouslySetInnerHTML={{ __html: introCopy }}
                />
              </>
            )}
            {(instagram || facebook) && (
              <div className="navigation__social">
                {facebook && (
                  <a
                    aria-label="Facebook Profile Link"
                    href={facebook}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={(e) => {
                      trackCustomEvent({
                        category: 'Social Link Contact',
                        action: 'Click',
                        label: 'Facebook',
                      })
                    }}
                    dangerouslySetInnerHTML={{ __html: facebookSVG }}
                  />
                )}
                {instagram && (
                  <a
                    aria-label="Instagram Profile Link"
                    href={instagram}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={(e) => {
                      trackCustomEvent({
                        category: 'Social Link Contact',
                        action: 'Click',
                        label: 'Instagram',
                      })
                    }}
                    dangerouslySetInnerHTML={{ __html: instagramSVG }}
                  />
                )}
              </div>
            )}
            <form>
              <div
                className={
                  'contact__field' + (form.firstName.error ? ' error' : '')
                }
              >
                <label htmlFor="firstName">{firstName}</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={form.firstName.value}
                  onChange={(e) =>
                    updateForm({ field: 'firstName', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'firstName' })}
                />
                <small>{firstNameValidation}</small>
              </div>
              <div
                className={
                  'contact__field' + (form.lastName.error ? ' error' : '')
                }
              >
                <label htmlFor="lastName">{lastName}</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={form.lastName.value}
                  onChange={(e) =>
                    updateForm({ field: 'lastName', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'lastName' })}
                />
                <small>{lastNameValidation}</small>
              </div>
              <div
                className={
                  'contact__field' + (form.email.error ? ' error' : '')
                }
              >
                <label htmlFor="email">{email}</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={form.email.value}
                  onChange={(e) =>
                    updateForm({ field: 'email', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'email' })}
                />
                <small>{emailValidation}</small>
              </div>
              <div
                className={
                  'contact__field' + (form.telephone.error ? ' error' : '')
                }
              >
                <label htmlFor="telephone">{telephone}</label>
                <input
                  type="text"
                  name="telephone"
                  id="telephone"
                  value={form.telephone.value}
                  onChange={(e) =>
                    updateForm({ field: 'telephone', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'telephone' })}
                />
                <small>{telephoneValidation}</small>
              </div>
              <div
                className={
                  'contact__field fullwidth' +
                  (form.subject.error ? ' error' : '')
                }
              >
                <label htmlFor="subject">{subject}</label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={form.subject.value}
                  onChange={(e) =>
                    updateForm({ field: 'subject', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'subject' })}
                />
                <small>{subjectValidation}</small>
              </div>
              <div
                className={
                  'contact__field fullwidth' +
                  (form.message.error ? ' error' : '')
                }
              >
                <label htmlFor="message">{message}</label>
                <textarea
                  name="message"
                  id="message"
                  value={form.message.value}
                  onChange={(e) =>
                    updateForm({ field: 'message', value: e.target.value })
                  }
                  onBlur={(e) => validateField({ field: 'message' })}
                />
                <small>{messageValidation}</small>
              </div>
              <div className={'contact__field fullwidth ' + formStatus}>
                <button className="button" onClick={submitForm}>
                  <span>
                    {sendMessage}
                    {formStatus === 'sending' && (
                      <div dangerouslySetInnerHTML={{ __html: loadingSVG }} />
                    )}
                  </span>
                </button>
                {formStatus === 'success' && <small>{successMessage}</small>}
                {formStatus === 'fail' && <small>{failMessage}</small>}
                {formStatus === 'invalid' && <small>{invalidMessage}</small>}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

// Components PropTypes
Contact.propTypes = {
  introTitle: PropTypes.string.isRequired,
  introCopy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      html: PropTypes.string,
    }),
  ]),
  instagram: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  firstNameValidation: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  lastNameValidation: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  telephoneValidation: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailValidation: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  subjectValidation: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageValidation: PropTypes.string.isRequired,
  sendMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  failMessage: PropTypes.string.isRequired,
  invalidMessage: PropTypes.string.isRequired,
}
Contact.defaultProps = {
  introCopy: '',
}

export default Contact
